.timer {
  padding: 10px 20px;
  margin-top: 4rem;
  font-size: 1.2rem;
}

@media screen and (max-width: 800px) {
  .timer {
    padding: 5px 8px;
  }
}
